<template>
  <section id="card-content-types">

    <b-row>
      <!-- BOXEA-->
      <b-col
        md="6"
        lg="4"
      >
        <b-card>
		<div	
			style="height: 150px;"
		>
			<b-img
				:src="require('@/assets/images/logo/BOXEA-LOGO.png')"
				center
				style="height:100%;"
			/>			
		</div><br>
			<b-img
			:src="require('@/assets/images/illustration/boxea.png')"
			center
			fluid
			rounded
		/><br>
			<b-card-text>
			Centre de self-stockage et un garde-meuble qui offre de nombreuses solutions de location de box sécurisés sur l’île de la Réunion
			</b-card-text>
			<b-button
			block
			Primary
			:to="{ name: 'boxea' }"
			>
			Visiter l'espace client
			</b-button>
			<b-button
			block
			Primary
			@click="openurl('https://www.boxea.fr/')"
			>
			Visiter le site web
			</b-button>
        </b-card>
      </b-col>

      <!-- IZIBOX-->
      <b-col
        md="6"
        lg="4"
      >
        <b-card>
		<div	
			style="height: 150px;"
		>
			<b-img
				:src="require('@/assets/images/logo/IZIBOX-LOGO.png')"
				center
				style="height:100%;"
			/>			
		</div><br>
		<b-img
			:src="require('@/assets/images/illustration/izibox.png')"
			center
			fluid
			rounded
		/><br>
          <b-card-text>
            Stockez, en toute liberté. IZIBOX c’est une offre Simple et transparente.
          </b-card-text>
			<b-button
			block
			Primary
			:to="{ name: 'izibox' }"
			>
			Visiter l'espace client
			</b-button>
			<b-button
			block
			Primary
			@click="openurl('https://www.izibox.re/')"
			>
			Visiter le site web
			</b-button>
        </b-card>
      </b-col>

      <!-- image card -->
      <b-col
        md="6"
        lg="4"
      >
        <b-card>
		<div	
			style="height: 150px;"
		>
			<b-img
				:src="require('@/assets/images/logo/DOMICIALIZ-LOGO.png')"
				center
				style="height:100%;"
			/>			
		</div><br>
		<b-img
			:src="require('@/assets/images/illustration/domicaliz.png')"
			center
			fluid
			rounded
		/><br>
          <b-card-text>
            Domicializ c'est la domiciliation facile à la Réunion !
          </b-card-text>
			<b-button
			block
			Primary
			:to="{ name: 'domicializ' }"
			>
			Visiter l'espace client
			</b-button>
			<b-button
			block
			Primary
			@click="openurl('https://domicializ.fr/')"
			>
			Visiter le site web
			</b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BButton, BImg,
} from 'bootstrap-vue'

export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardText,
		BButton,
		BImg,
	},
	data() {
		return {
		  listData: [
			{ text: 'Cras justo odio' },
			{ text: 'Dapibus ac facilisis in' },
			{ text: 'Vestibulum at eros' },
		  ],
		  kitchenSinkList: [
			{ text: 'Cras justo odio' },
			{ text: 'Vestibulum at eros' },
		  ],
	}
	},
	methods: {
		openurl: function (url) {   
		  window.open(url, '_blank')
		},
	},
}
</script>
