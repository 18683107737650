<template>
  <section id="dashboard-ecommerce"><br>

      <home-medal :resultSoc='ResultSoc' />

  </section>
</template>
<script>
import { VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import HomeMedal from './HomeMedal.vue'
	
export default {
	components: {
		HomeMedal,
	},
	directives: {
		Ripple,
		'b-tooltip': VBTooltip,
	},
	data() {
		return {
			resultSoc: [],
		}
	},
	created() {
		// this.$http.get('/admin/lstcompte')
		// .then(response => {
		// 	if (response.status === 200) {
		// 		this.ResultSoc = response.data.ResultSoc
		// 	} else {
		// 		this.$router.push('/login')	
		// 	}
		// })

	},	  
	methods: {
		onRowClick(params) {
			this.selctrow = { C_libelle: params.row.C_libelle, C_adresse: `${params.row.C_adresse} ${params.row.C_cp} ${params.row.C_ville}`, C_tel: params.row.C_tel, C_logo: params.row.C_logo }
			this.mediaData = [
				{ avatar: 'UserIcon', title: 'Contact', subtitle: `${params.row.C_prenom}  ${params.row.C_nom}` },
				{ avatar: 'PhoneCallIcon', title: 'Téléphone', subtitle: params.row.C_tel },
				{ avatar: 'MailIcon', title: 'E-mail', subtitle: params.row.C_email },
			]
			if (params.row.B_signatureactive === 1) {
				if (params.row.B_prod === 1) {
					this.mediaData.push({
						avatar: 'CheckCircleIcon',
						title: 'Signature',
						subtitle: `En production : ${params.row.I_nbmois} signatures par mois`,
					})	
				} else {
					this.mediaData.push({
						avatar: 'CheckCircleIcon',
						title: 'Signature',
						subtitle: `En test : ${params.row.I_nbmois} signatures par mois`,
					})	
				}
			}
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
